body {
  background-color: #230c33;
  color: #f2cee6;
}

.types-table {
  display: grid;
  text-align: center;
  justify-content: center;
}

.types-table > div {
  border: 1.5px solid;
  border-radius: 1em;
  color: black;
}

.colors-legend {
  display: grid;
  grid-template-columns: repeat(1, 100vw);
  justify-content: center;
  grid-gap: 10px;
  color: black;
}

.colors-div {
  display: grid;
  justify-content: center;
  color: black;
}

.colors-legend > div > div {
  border: 1.5px solid;
}

.input-fields {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(1, 40em);
  margin-bottom: 2em;
  margin-top: 1em;
}

.input-fields > strong {
  margin-top: 1em;
}

.pokemon-select {
  margin-top: 0.5em;
}

.footer {
  width: 100vw;
  background-color: #230c33;
  color: #f2cee6;
  margin-top: 0.5em;
}

.footer a {
  color: #db85be;
}

.language-change {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
}

.language-change > button {
  background: none;
  color: #f2cee6;
  border: none;
}

.language-change > button:hover {
  border: 1px solid;
}

@media (max-width: 850px) {
  .App {
    text-align: center;
    margin-top: 2em;
  }

  .types-table {
    grid-template-columns: repeat(3, 6em);
    grid-gap: 6px;
    grid-auto-rows: 3.5em;
    padding: 0;
  }

  .types-table > div {
    line-height: 1.5em;
  }
  .types-select {
    display: grid;
    grid-template-rows: repeat(2, 2em);
    grid-template-columns: repeat(1, 15em);
    justify-content: center;
    text-align: center;
    grid-gap: 1em;
  }
  .colors-legend {
    grid-auto-rows: 2em;
    margin: 0 1em;
  }

  .colors-div {
    grid-template-columns: repeat(5, 4em);
    grid-gap: 0.1em;
    grid-auto-rows: 2em;
    margin: 1em 1em;
    line-height: 2em;
  }

  .immune-div {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 4em);
    grid-gap: 0.1em;
    grid-auto-rows: 2em;
    line-height: 2em;
    margin-top: 1em;
  }

  .colors-legend > div > div {
    border-radius: 0.5em;
  }

  .input-fields {
    grid-gap: 0.8em;
    grid-template-columns: repeat(1, 20em);
    grid-auto-rows: 4em;
  }

  .language-change {
    font-size: 8px;
  }

  .color-legend-label {
    margin-top: 1em;
  }
}

@media (min-width: 850px) {
  .App {
    text-align: center;
    margin-top: 1em;
  }

  .types-table {
    grid-template-columns: repeat(5, 10em);
    grid-gap: 10px;
    grid-auto-rows: 80px;
    font-size: 1em;
    line-height: 1em;
    padding: 1em;
  }

  .types-table > div {
    padding: 1em;
  }

  .colors-legend {
    grid-auto-rows: 60px;
    margin: 2em 0;
  }
  .types-select {
    display: grid;
    grid-template-columns: repeat(2, 15em);
    justify-content: center;
    text-align: center;
    grid-gap: 1em;
  }
  .colors-div {
    grid-template-columns: repeat(5, 6em);
    grid-gap: 10px;
    grid-auto-rows: 60px;
  }

  .immune-div {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 6em);
    grid-gap: 10px;
    grid-auto-rows: 60px;
  }

  .colors-legend > div > div {
    border-radius: 1em;
    padding: 1em;
  }

  .input-fields {
    grid-gap: 10px;
    grid-auto-rows: 30px;
  }

  .color-legend-label {
    margin-top: 3em;
  }

  .footer {
    position: absolute;
    bottom: 0;
    max-width: 100vw;
  }
}
