.default {
  background-color: #bfb48f;
}

.half {
  background-color: #b82836;
}

.double {
  background-color: #70ae6e;
}

.immune {
  background-color: #564e58;
}

.quarter {
  background-color: #722d42;
}

.quad {
  background-color: #7798ab;
}
